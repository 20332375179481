import { createStore } from 'vuex'
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
import axios from 'axios';
// import {router} from '../main.js'

export default createStore({
	state: {
		apiUrl: process.env.VUE_APP_API_URL+'/api/',
		pdfUrl: process.env.VUE_APP_API_URL+'/',
		user:null,
		organization:null,
		token:"",
		permissions:[]
	},
	
	mutations: {
		setUser(state, user){
			state.user = user;
		},
		setOrganization(state, organization){
			state.organization = organization;
		},
		setToken(state, token){
			state.token = token;
		},		
		setUrl(state, url){
			state.apiUrl = url;
		},
		setPermissions(state, permissions){
			state.permissions = permissions;
		},
	},

	getters: {
		user(state){
			return state.user;
		},
		token(state){
			return state.token;
		},
		organization(state){
			return state.organization;
		},
		apiUrl(state){
			return state.apiUrl;
		},
		pdfUrl(state){
			return state.pdfUrl;
		},
		permissions(state){
			return state.permissions;
		},
	},

	actions: {
		async setUser(context,payload) {
			sessionStorage.setItem("user",  JSON.stringify(payload))
			await context.commit('setUser',payload);
		},
		async setToken(context,payload) {
			sessionStorage.setItem("token",  payload)
			await context.commit('setToken',payload);
		},
		async setPermissions(context,payload) {
			sessionStorage.setItem("permissions",  JSON.stringify(payload))
			await context.commit('setPermissions',payload);
		},
		async setOrganization(context,payload) {
			await context.commit('setOrganization',payload);
		},
		async logout(context) {
			sessionStorage.removeItem('user');
			sessionStorage.removeItem('token');
			sessionStorage.removeItem('permissions');
			await context.commit('setUser',null);
			await context.commit('setToken',"");
			await context.commit('setPermissions',[]);
		},


		auth(context,payload) {
			return new Promise((resolve, reject) => {
				axios.post(this.state.apiUrl+payload.uri,payload.data)
				.then(function (response) {
					resolve(response);
				})
				.catch(function (error) {
					reject(error);
				});
			});
		},

		get(context,payload) {
			return new Promise((resolve, reject) => {
				axios.get(this.state.apiUrl+payload.uri,payload.data, {
                    headers: {
                        'Authorization': 'Bearer' + ' ' + context.getters.token
                    }
                }).then(function (response) {
					resolve(response);
				})
				.catch(function (error) {
					reject(error);
				});
			});
		},

		post(context,payload) {
			return new Promise((resolve, reject) => {
				axios.post(this.state.apiUrl+payload.uri,payload.data, {
                    headers: {
                        'Authorization': 'Bearer' + ' ' + context.getters.token
                    }
                }).then(function (response) {
					resolve(response);
				})
				.catch(function (error) {
					reject(error);
					if(error.response.data.message == "Unauthenticated.")
					{
						context.dispatch('logout');
						window.location.href = "/#/login"
						window.location.reload();
					}
				});
			});
		},

		patch(context,payload) {
			return new Promise((resolve, reject) => {
				axios.patch(this.state.apiUrl+payload.uri,payload.data, {
				headers: {
					'Authorization': 'Bearer' + ' ' + context.getters.token
				}
			})
				.then(function (response) {
					resolve(response);
				})
				.catch(function (error) {
					reject(error);
				});
			});
		},

		delete(context,payload) {
			return new Promise((resolve, reject) => {
				axios.delete(this.state.apiUrl+payload.uri)
				.then(function (response) {
					resolve(response);
				})
				.catch(function (error) {
					reject(error);
				});
			});
		},

		upload(context,payload) {
			return new Promise((resolve, reject) => {
				axios.post(this.state.apiUrl+payload.uri,payload.data, {
					headers: {
						'Authorization': 'Bearer' + ' ' + context.getters.token,
						'Content-Type': 'multipart/form-data'
					}
				}).then(function (response) {
					resolve(response);
				})
				.catch(function (error) {
					reject(error);
				});
			});
		},

		async error(context,description) {
			await createToast({
				title: 'Error',
				description: description || "The given data was invalid."
			},
			{
				showIcon: true,
				hideProgressBar: true,
				type: 'danger',
				position: 'top-right'
			})
		},

		async success(context,description) {
			await createToast({
				title: 'Success',
				description: description || "Data is successfuly subbmited."
			},
			{
				showIcon: true,
				hideProgressBar: true,
				type: 'success',
				position: 'top-right'
			})
		},
	}
})
