<template>
    <div class="hold-transition login-page">
        <div class="login-box">
            <div class="card">
                <div class="card-header text-center">
                    <img src="@/assets/logo.jpg" alt="Logo" height="80" /> <br>
                    <h4>GOLDEN ACES</h4>
                </div>
                <div class="card-body" v-if="!status">
                    <p class="login-box-msg">Sign in to start your session</p>
                    <form autocomplete="off" @submit.prevent="sendOtp">
						<div class="form-group mb-3">
							<div class="input-group">
								<div class="input-group-prepend">
									<div class="input-group-text">
										<span class="fa fa-mobile"></span>
									</div>
								</div>
								<input :disabled="status" type="number" class="form-control" :class="{'is-invalid': errors.mobile_no}" placeholder="Enter your mobile number" v-model="user.mobile_no" ref="mobile_no">
								<span v-if="errors.mobile_no" class="invalid-feedback">{{ errors.mobile_no[0] }}</span>
							</div>
						</div>
						<div class="form-group">
							<button method="submit" class="btn btn-primary btn-block">Send OTP</button>
						</div>
					</form>
                </div>

                <div class="card-body" v-if="status">
                    <p class="login-box-msg">OTP sent to {{ user.mobile_no }}</p>
                    <form autocomplete="off" @submit.prevent="verifyOtp">
						<div class="form-group mb-3">
							<div class="input-group">
								<div class="input-group-prepend">
									<div class="input-group-text">
										<span class="fas fa-keyboard"></span>
									</div>
								</div>
								<input type="number" class="form-control" :class="{'is-invalid': errors.otp}" placeholder="Enter OTP" v-model="user.otp" ref="otp">
								<span v-if="errors.otp" class="invalid-feedback">{{ errors.otp[0] }}</span>
							</div>
						</div>
						<div class="form-group">
							<button method="submit" class="btn btn-primary btn-block">Validate OTP</button>
						</div>
                        <a class="text-center" href="@" @click.prevent="resendOtp">
                            <p>Resend OTP</p>
                        </a>
					</form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Login",
        data() {
            return {
                user: {
                    mobile_no: "",
                    otp: "",
                },
                status:false,
                errors: [],
            };
        },
        mounted() {
            this.$refs.mobile_no.focus();
        },
        methods: {
            sendOtp() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store.dispatch("auth", { uri: "admin/sendOtp", data: vm.user })
				.then(function () {
                    vm.status = true;
					loader.hide();
                    vm.$refs.otp.focus();
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
            },

            resendOtp() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store.dispatch("auth", { uri: "resendOtp", data: vm.user })
				.then(function () {
                    vm.status = true;
					loader.hide();
                    vm.$refs.otp.focus();
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
            },

            verifyOtp() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store.dispatch("auth", { uri: "verifyOtp", data: vm.user })
				.then(function (response) {
					loader.hide();
                    vm.status = true
					vm.$store.dispatch("setUser", response.data.user);
                    vm.$store.dispatch("setToken", response.data.token);
                    vm.$store.dispatch("setPermissions", response.data.permissions);
                    vm.$router.push("/");
                    location.reload()
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
            }
        },
    };
</script>

<style scoped>
    .card-body {
        padding-left: 30px;
        padding-right: 30px;
    }
</style>
