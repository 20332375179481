<template>
    <div class="content-wrapper">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="#">
                        <i class="fa fa-home"></i>
                        Dashboard
                    </a>
                </li>
                <li class="breadcrumb-item active">Wallet Balance Report</li>
            </ol>
        </nav>
        <section class="content">
            <div class="container-fluid">
                <div class="row justify-content-md-center">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">Wallet Balance Report</h3>
                                <div class="card-tools">
                                    <label>Total : {{ total_wallet_balance }}</label>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label for="from_date">Report Type</label>
                                            <select class="form-control form-control-sm" v-model="meta.report_type">
                                                <option value="">Select Report Type</option>
                                                <option value="receivable">Receivable Report</option>
                                                <option value="payable">Payable Report</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label for="customer_id">Customer </label>
                                            <Customer :class="{'is-invalid': errors.customer_id}" :customClass="{'is-invalid': errors.customer_id}" :initialize="meta.customer_name" @selectCustomer="selectCustomer($event)" ref="customer_id"></Customer>
                                            <span v-if="errors.customer_id" class="invalid-feedback">{{ errors.customer_id[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label for="session_log_id">Sessions</label>
                                            <select class="form-control form-control-sm" v-model="meta.session_log_id">
                                                <option value="">Seelct Session</option>
                                                <option v-for="(session_log,key) in session_logs" :key="key" :value="session_log.session_log_id">
                                                    {{ session_log.start }} - {{ session_log.end }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group" style="margin-top: 26px !important;">
                                            <button class="btn btn-sm btn-primary mr-2" @click="search"><i class="fa fa-search mr-1"></i>Search</button>
                                            <button class="btn btn-sm btn-danger" @click="discard"><i class="fa fa-times mr-1"></i>Discard</button>
                                        </div>
                                    </div>
                                </div>
                                    
                                <div class="table-responsive">
                                    <table class="table table-bordered table-striped table-sm">
                                        <thead>
                                            <tr>
                                                <th class="text-center">#</th>
                                                <th @click="sort('customer_no')">Customer No
                                                    <span>
                                                        <i v-if="meta.keyword=='customer_no' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                        <i v-else-if="meta.keyword=='customer_no' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                        <i v-else class="fas fa-sort"></i>
                                                    </span>  
                                                </th>
                                                <th @click="sort('customer_name')">Customer Name
                                                    <span>
                                                        <i v-if="meta.keyword=='customer_name' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                        <i v-else-if="meta.keyword=='customer_name' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                        <i v-else class="fas fa-sort"></i>
                                                    </span>  
                                                </th>
                                                <th class="text-center" @click="sort('credit_limit')">Credit Limit
                                                    <span>
                                                        <i v-if="meta.keyword=='credit_limit' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                        <i v-else-if="meta.keyword=='credit_limit' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                        <i v-else class="fas fa-sort"></i>
                                                    </span>  
                                                </th>
                                                <th class="text-center" @click="sort('wallet_balance')">Wallet Balance
                                                    <span>
                                                        <i v-if="meta.keyword=='wallet_balance' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                        <i v-else-if="meta.keyword=='wallet_balance' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                        <i v-else class="fas fa-sort"></i>
                                                    </span>  
                                                </th>
                                                <template v-if="report_type=='receivable'">
                                                    <th class="text-center" @click="sort('available_credit_limit')">Avail Credit Limit
                                                    <span>
                                                        <i v-if="meta.keyword=='available_credit_limit' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                        <i v-else-if="meta.keyword=='available_credit_limit' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                        <i v-else class="fas fa-sort"></i>
                                                    </span>  
                                                </th>
                                                </template>
                                                <th class="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(customer,key) in customers" :key="key">
                                                <td class="text-center">{{meta.from+key}}</td>  
                                                <td>
                                                    <router-link :to="'/customers/'+customer.customer_id+'/view'">
                                                        {{ customer.customer_no }}
                                                    </router-link>
                                                </td>
                                                <td>
                                                    <router-link :to="'/customers/'+customer.customer_id+'/view'">
                                                        {{ customer.customer_name }}
                                                    </router-link>
                                                </td>
                                                <td class="text-center">{{ customer.credit_limit }}</td>
                                                <td class="text-center">{{ (customer.wallet_balance*1).toFixed(0) }}</td>
                                                <template v-if="report_type=='receivable'">
                                                    <td class="text-center" v-if="customer.available_credit_limit<0" style="background: #FFC0C0;">
                                                        <strong>{{ customer.available_credit_limit }}</strong>
                                                    </td>
                                                    <td class="text-center" v-else>
                                                        <strong>{{ customer.available_credit_limit }}</strong>
                                                    </td>
                                                </template>
                                                <td class="text-center">
                                                    <button class="btn btn-xs btn-outline-success" @click.prevent="callCustomer(customer.mobile_no)">
                                                        <i class="fa fa-phone"></i>
                                                    </button>
                                                    <a class="btn btn-xs btn-outline-primary" :href="'mailto:'+customer.email">
                                                        <i class="fa fa-envelope"></i>
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Pagination from "@/components/Pagination.vue";
    let Customer = require('../../components/Customer.vue').default;
    export default {
        name: "User.Index",
        components: { Pagination, Customer },
        data() {
            return {
                status: true,
                meta: {
                    branch_id:this.$store.getters.user.branch_id,
                    report_type: '',
                    customer_id:'',
                    customer_no:'',
                    customer_name:'',
                    session_log_id:'',
                    order_by: "asc",
                    keyword: "customer_no",
                    per_page: 50,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                },
                report_type:'',
                total_wallet_balance:0,
                session_logs:[],
                errors: [],
                customers:[],
            };
        },
        mounted() {
            let vm = this;
            vm.discard();
            vm.getSessionLogs()
        },

        methods: {
            formatCurrency(amount) {
                const formattedAmount = new Intl.NumberFormat('en-IN', {
                    style: 'currency',
                    currency: 'INR'
                }).format(amount);

                return formattedAmount;
            },
            index() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "paginateCustomerWithBalance", data: vm.meta })
                .then(function (response) {
                    vm.customers = response.data.customers.data;
                    vm.total_wallet_balance = response.data.total_wallet_balance;
                    vm.report_type = response.data.report_type;
                    vm.meta.totalRows = response.data.customers.total;
                    vm.meta.lastPage = response.data.customers.last_page;
                    vm.meta.from = response.data.customers.from;
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },
            getSessionLogs() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getSessionLogs", data: vm.meta })
                .then(function (response) {
                    vm.session_logs = response.data.data;
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },
            callCustomer(to) {
                let vm = this;
                let loader = vm.$loading.show();
                let data = {
                    From: '0'+vm.$store.getters.user.mobile_no,
                    To:'0'+to
                }
                let uri = { uri: "customerCallConnect", data: data };
                vm.$store.dispatch("post", uri)
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "Call connected, please wait for sometime");
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },
            discard() {
                let vm = this;
                vm.meta.report_type = '';
                vm.meta.customer_id = '';
                vm.meta.customer_no = '';
                vm.meta.customer_name = '';
                vm.search()
            },
            selectCustomer(e){
                let vm = this;
                const customer = e.target.value;
                vm.meta.customer_id = customer.customer_id;
                vm.meta.customer_no = customer.customer_no;
                vm.meta.customer_name = customer.customer_name;
                vm.meta.session_log_id = customer.session_log_id;
            },
            onPageChange(page) {
                this.meta.page = page;
                this.index();
            },
            search() {
                let vm = this;
                vm.meta.page = 1;
                vm.index()
            },
            sort(field) {
                this.meta.keyword = field;
                this.meta.order_by = this.meta.order_by=='asc' ? 'desc' : 'asc';
                this.search();
            },
        },
    };
</script>
