import { createRouter, createWebHashHistory } from 'vue-router'
import store from '@/store/index.js';

// Authentication
import Login from '@/views/auth/Login.vue'
import Waiting from '@/views/Waiting.vue'
import ForgotPassword from '@/views/auth/ForgotPassword.vue'
import ResetPassword from '@/views/auth/ResetPassword.vue'
// import Dashboard from '@/views/Dashboard.vue'
import Profile from '@/views/auth/Profile.vue'
import ChangeBranch from '@/views/auth/ChangeBranch.vue'
import PendingTransactions from '@/views/PendingTransactions.vue'

//configuration
import Organization from '@/views/configuration/Organization.vue'
import Roles from '@/views/configuration/Role.vue'
import Sliders from '@/views/configuration/Slider.vue'
import Notifications from '@/views/configuration/Notification.vue'
import Configurations from '@/views/configuration/Configuration.vue'
import PaymentModes from '@/views/configuration/PaymentMode.vue'
import PaymentTerms from '@/views/configuration/PaymentTerms.vue'
import Groups from '@/views/configuration/Group.vue'
import NotificationUsers from '@/views/configuration/NotificationUser.vue'
import Tables from '@/views/configuration/Table.vue'
import Branches from '@/views/configuration/Branch.vue'
import Denominations from '@/views/configuration/Denomination.vue'
import Promotions from '@/views/configuration/Promotion.vue'
import Occupations from '@/views/configuration/Occupation.vue'
import Checkouts from '@/views/configuration/Checkout.vue'
import Terms from '@/views/configuration/Term.vue'
import Pages from '@/views/configuration/Pages.vue'
import ReferenceTypes from '@/views/configuration/ReferenceType.vue'
import Categories from '@/views/configuration/Category.vue'
import Accounts from '@/views/configuration/Account.vue'
import Taxs from '@/views/configuration/Tax.vue'
import Videos from '@/views/configuration/Videos.vue'

// kitchen
import Kitchens from '@/views/configuration/Kitchen.vue'

// itemCategory
import ItemCategories from '@/views/configuration/ItemCategory.vue'

// items
import CreateItem from '@/views/item/Create.vue'
import Items from '@/views/item/Index.vue'

//Stacks
import Stacks from '@/views/stack/Index.vue'
import CreateStack from '@/views/stack/Create.vue'

// permissions
import Permissions from '@/views/Permissions.vue'

//Users
import Users from '@/views/user/Index.vue'
import CreateUser from '@/views/user/Create.vue'

//Emnployees
import Employees from '@/views/employee/Index.vue'
import CreateEmployee from '@/views/employee/Create.vue'

//Customers
import Customers from '@/views/customer/Index.vue'
import CreateCustomer from '@/views/customer/Create.vue'
import ViewCustomer from '@/views/customer/View.vue'
import CustomerLedger from '@/views/customer/Ledger.vue'
import CustomerCredit from '@/views/customer/Credit.vue'
import CustomerAttendance from '@/views/customer/Attendance.vue'

//Transactions
import Transactions from '@/views/transaction/Index.vue'
import CreateTransactionDenomination from '@/views/transaction/TransactionDenomination.vue'
import CreateTransaction from '@/views/transaction/Create.vue'
import ViewTransaction from '@/views/transaction/View.vue'
import depositTransaction from '@/views/transaction/Deposit.vue'
import withdrawTransaction from '@/views/transaction/Withdraw.vue'

//Sessions
import Sessions from '@/views/session/Index.vue'
import CreateSession from '@/views/session/Create.vue'
import Report from '@/views/session/Report.vue'
import SessionHistory from '@/views/session/History.vue'
import ViewSession from '@/views/session/ViewSession.vue'

//Reports
import Reports from '@/views/reports/Index.vue'

//Logs
import Logs from '@/views/log/Index.vue'
import UserLogs from '@/views/log/Employee.vue'

// Database
import Database from '@/views/configuration/Database.vue'
import Message from '@/views/configuration/Message.vue'

//Expenses
import Expenses from '@/views/expense/Index.vue'
import CreateExpense from '@/views/expense/Create.vue'
import ViewExpense from '@/views/expense/View.vue'
import DailyExpense from '@/views/expense/Daily.vue'
import ConsolidatedExpense from '@/views/expense/Consolidated.vue'

//Vendors
import Vendors from '@/views/vendor/Index.vue'
import CreateVendor from '@/views/vendor/Create.vue'

// Tips
import TipsReport from '@/views/tips/Tips.vue'
import WithdrawTips from '@/views/tips/Withdraw.vue'

// Credit
import CreditReport from '@/views/credit/Index.vue'

// Wallet Balance Report
import walletBalanceReport from '@/views/customer/wallet.vue'

const routes = [

	// Authentication
	{ 
		path: '/waiting', name: 'Waiting', 
		component: Waiting, 
	},
	{ 
		path: '/login', name: 'Login', 
		component: Login,  meta: { unAuth: true, permission:'All' }
	},
	{ 
		path: '/forgot-password', name: 'ForgotPassword.Index', 
		component: ForgotPassword,  meta: { unAuth: true, permission:'profile.view' }  
	},
	{ 
		path: '/reset-password', name: 'ResetPassword.Index', 
		component: ResetPassword,  meta: { unAuth: true, permission:'profile.view' }  
	},
	{ 
		path: '/', name: 'Home', 
		component: Sessions,  meta: { auth: true, permission:'All' }  
	},
    { 
		path: '/dashboard', name: 'Dashboard', 
		component: Sessions,  meta: { auth: true, permission:'All' }  
	},
	{ 
		path: '/pending-transactions', name: 'PendingTransaction.Index', 
		component: PendingTransactions,  meta: { auth: true, permission:'pending-transaction.view' }  
	},
	{ 
		path: '/transactions/:transaction_id/view', name:'Transactions.View', 
		component:ViewTransaction,  meta: { auth: true, permission:'transaction.view' } 
	},
	{ 
		path: '/profile', name: 'Profile', 
		component: Profile,  meta: { auth: true, permission:'profile.view' }  
	},
	{ 
		path: '/change-branch', name: 'ChangeBranch', 
		component:ChangeBranch,  meta: { auth: true, permission:'branch.view' }  
	},

	// Configiration
	{ 
		path: '/organizations', name: 'Organization', 
		component: Organization,  meta: { auth: true, permission:'organizations.view' }  
	},
	{ 
		path: '/roles', name: 'Role', 
		component: Roles,  meta: { auth: true, permission:'roles.view' }  
	},
	{ 
		path: '/sliders', name: 'Slider', 
		component: Sliders,  meta: { auth: true, permission:'sliders' }  
	},
	{ 
		path: '/terms', name: 'Term', 
		component: Terms,  meta: { auth: true, permission:'terms' }  
	},
	{ 
		path: '/notifications', name: 'Notification', 
		component: Notifications,  meta: { auth: true, permission:'notifications' }  
	},
	{ 
		path: '/configurations', name: 'Configuration', 
		component: Configurations,  meta: { auth: true, permission:'configurations' }  
	},
	{ 
		path: '/payment_modes', name: 'PaymentMode', 
		component: PaymentModes,  meta: { auth: true, permission:'payment_modes' }  
	},
	{ 
		path: '/payment_terms', name: 'PaymentTerm', 
		component: PaymentTerms,  meta: { auth: true, permission:'payment_terms' }  
	},
	{ 
		path: '/groups', name: 'Group', 
		component: Groups,  meta: { auth: true, permission:'groups' }  
	},

	{ 
		path: '/tables', name: 'Table', 
		component: Tables,  meta: { auth: true, permission:'tables' }  
	},

	{ 
		path: '/branches', name: 'Branch', 
		component: Branches,  meta: { auth: true, permission:'branches' }  
	},

	{ 
		path: '/denominations', name: 'Denomination', 
		component: Denominations,  meta: { auth: true, permission:'denominations' }  
	},

	{ 
		path: '/promotions', name: 'Promotion', 
		component: Promotions,  meta: { auth: true, permission:'promotions' }  
	},

	{ 
		path: '/occupations', name: 'Occupation', 
		component: Occupations,  meta: { auth: true, permission:'occupations' }  
	},
	{ 
		path: '/checkouts', name: 'Checkout', 
		component: Checkouts,  meta: { auth: true, permission:'checkouts' }  
	},
	{ 
		path: '/notification_users', name: 'NotificationUser', 
		component: NotificationUsers,  meta: { auth: true, permission:'notification_users' }  
	},
	{ 
		path: '/pages', name: 'Page', 
		component: Pages,  meta: { auth: true, permission:'pages' }  
	},
	{ 
		path: '/reference_types', name: 'ReferenceType', 
		component: ReferenceTypes,  meta: { auth: true, permission:'reference_types' }  
	},
	{ 
		path: '/taxs', name: 'Tax', 
		component: Taxs,  meta: { auth: true, permission:'taxs' }  
	},
	{ 
		path: '/videos', name: 'Video', 
		component: Videos,  meta: { auth: true }  
	},

	// Kitchen
	{ 
		path: '/kitchens', name: 'Kitchen', 
		component: Kitchens,  meta: { auth: true, permission:'kitchens' }  
	},

	

	// Item Category
	{ 
		path: '/item_categories', name: 'ItemCategory', 
		component: ItemCategories,  meta: { auth: true, permission:'item_categories' }  
	},

	//Items 
	{ 
		path: '/items/create', name: 'Items.Create', 
		component: CreateItem,  meta: { auth: true, permission:'items.create' } 
	},
	{ 
		path: '/items', name: 'Items.Index', 
		component: Items,  meta: { auth: true, permission:'items.view' }  
	},
	{ 
		path: '/items/:item_id/edit', name:'Items.Edit', 
		component:CreateItem,  meta: { auth: true, permission:'items.update' } 
	},

	// Stacks
	{ 
		path: '/stacks', name: 'Stacks.Index', 
		component: Stacks,  meta: { auth: true, permission:'stacks.view' }  
	},
	{ 
		path: '/stacks/create', name: 'Stacks.Create', 
		component: CreateStack,  meta: { auth: true, permission:'stacks.create' } 
	},
	{ 
		path: '/stacks/:stack_id/edit', name:'Stacks.Edit', 
		component:CreateStack,  meta: { auth: true, permission:'stacks.update' } 
	},


	// Users
	{ 
		path: '/users', name: 'Users.Index', 
		component: Users,  meta: { auth: true, permission:'users.view' }  
	},
	{ 
		path: '/users/create', name: 'Users.Create', 
		component: CreateUser,  meta: { auth: true, permission:'users.create' } 
	},
	{ 
		path: '/users/:user_id/edit', name:'Users.Edit', 
		component:CreateUser,  meta: { auth: true, permission:'users.update' } 
	},

	// Employees
	{ 
		path: '/employees', name: 'Employees.Index', 
		component: Employees,  meta: { auth: true, permission:'employees.view' }  
	},
	{ 
		path: '/employees/create', name: 'Employees.Create', 
		component: CreateEmployee,  meta: { auth: true, permission:'employees.create' } 
	},
	{ 
		path: '/employees/:employee_id/edit', name:'Employees.Edit', 
		component:CreateEmployee,  meta: { auth: true, permission:'employees.create' } 
	},

	// Customers
	{ 
		path: '/customers', name: 'Customers.Index', 
		component: Customers,  meta: { auth: true, permission:'customers.view' }  
	},
	{ 
		path: '/customers/create', name: 'Customers.Create', 
		component: CreateCustomer,  meta: { auth: true, permission:'customers.create' } 
	},
	{ 
		path: '/customers/:customer_id/edit', name:'Customers.Edit', 
		component:CreateCustomer,  meta: { auth: true, permission:'customers.update' } 
	},
	{ 
		path: '/customers/:customer_id/view', name:'Customers.View', 
		component:ViewCustomer,  meta: { auth: true, permission:'Customers.view' }   
	},
	{ 
		path: '/customers/ledger', name: 'Customers.Ledger', 
		component: CustomerLedger,  meta: { auth: true, permission:'customers.ledger' }  
	},
	{ 
		path: '/customers/credit', name: 'Customers.Credit', 
		component: CustomerCredit,  meta: { auth: true, permission:'customers.credit' }  
	},
	{ 
		path: '/customers/attendance', name: 'Customers.Attendance', 
		component: CustomerAttendance,  meta: { auth: true, permission:'customers.attendance' }  
	},

	// Transactions
	{ 
		path: '/transactions', name: 'Transactions.Index', 
		component: Transactions,  meta: { auth: true, permission:'transactions.view' }  
	},
	{ 
		path: '/transactions/create', name:'Transactions.Create', 
		component:CreateTransaction,  meta: { auth: true, permission:'transactions.create' } 
	},
	{ 
		path: '/transactions/:transaction_id/create', name:'TransactionDenomination.Create', 
		component:CreateTransactionDenomination,  meta: { auth: true, permission:'transaction-denomination.create' } 
	},
	
	{ 
		path: '/transactions/:transaction_id/deposit', name:'transactions.deposit', 
		component:depositTransaction,  meta: { auth: true, permission:'transactions.deposit' } 
	},
	{ 
		path: '/transactions/:transaction_id/withdraw', name:'transactions.withdraw', 
		component:withdrawTransaction,  meta: { auth: true, permission:'transactions.withdraw' } 
	},
	
	// Sassions
	{ 
		path: '/sessions', name: 'Sessions.Index', 
		component: Sessions,  meta: { auth: true, permission:'sessions.view' }  
	},
	{ 
		path: '/sessions/create', name:'Sessions.Create', 
		component: CreateSession,  meta: { auth: true, permission:'sessions.Create' }   
	},
	{ 
		path: '/sessions/history', name: 'Sessions.history', 
		component: SessionHistory,  meta: { auth: true, permission:'sessions.view' }  
	},
	{ 
		path: '/sessions/:session_id/view', name:'sessions.View', 
		component:ViewSession,  meta: { auth: true, permission:'session.view' } 
	},
	{ 
		path: '/sessions/report', name:'Report.Summary', 
		component:Report,  meta: { auth: true, permission:'Sessions.report' }   
	},
	

	//Reports
	{
		path: '/reports', name: 'Reports.Index', 
		component: Reports,  meta: { auth: true,  permission:'Reports.view' } 
	},
	
	//logs
	{ 
		path: '/logs', name: 'Logs.Index', 
		component: Logs,  meta: { auth: true, permission:'logs.view' }  
	},
	{ 
		path: '/logs/employees', name: 'Logs.Employee', 
		component: UserLogs,  meta: { auth: true, permission:'logs.view' }  
	},
	//Permissions
	{ 
		path: '/permissions', name: 'Permissions', 
		component: Permissions,  meta: { auth: true, permission:'permissions' }  
	},
	// Database
	{ 
		path: '/database', name: 'Database', 
		component: Database,  meta: { auth: true, permission:'database' }  
	},
	{ 
		path: '/messages', name: 'Message', 
		component: Message,  meta: { auth: true, permission:'message' }  
	},

	// Categories 
	{ 
		path: '/categories', name: 'Categories', 
		component: Categories,  meta: { auth: true, permission:'categories' }  
	},

	// Accounts
	{ 
		path: '/accounts', name: 'Accounts', 
		component: Accounts,  meta: { auth: true, permission:'accounts' }  
	},

	// Expenses
	{ 
		path: '/expenses', name: 'Expenses.Index', 
		component: Expenses,  meta: { auth: true, permission:'expenses.view' }  
	},
	{ 
		path: '/expenses/create', name: 'Expenses.Create', 
		component: CreateExpense,  meta: { auth: true, permission:'expenses.create' } 
	},
	{ 
		path: '/expenses/:expense_id/edit', name:'Expenses.Edit', 
		component:CreateExpense,  meta: { auth: true, permission:'expenses.update' } 
	},
	{ 
		path: '/expenses/:expense_id', name:'Expenses.View', 
		component:ViewExpense,  meta: { auth: true, permission:'expenses.view' } 
	},
	{ 
		path: '/expenses/daily', name:'Expenses.Daily', 
		component:DailyExpense,  meta: { auth: true, permission:'expenses.daily' } 
	},
	{ 
		path: '/expenses/consolidated', name:'Expenses.Consolidated', 
		component:ConsolidatedExpense,  meta: { auth: true, permission:'expenses.consolidated' } 
	},

	// Vendors
	{ 
		path: '/vendors', name: 'Vendors.Index', 
		component: Vendors,  meta: { auth: true, permission:'vendors.view' }  
	},
	{ 
		path: '/vendors/create', name: 'Vendors.Create', 
		component: CreateVendor,  meta: { auth: true, permission:'vendors.create' } 
	},
	{ 
		path: '/vendors/:vendor_id/edit', name:'Vendors.Edit', 
		component:CreateVendor,  meta: { auth: true, permission:'vendors.update' } 
	},

	// Tips
	{ 
		path: '/tips', name: 'Report.Tips', 
		component: TipsReport,  meta: { auth: true, permission:'report.tips' } 
	},
	{ 
		path: '/tips/withdraw/:user_id/:balance', name: 'Report.Tips.Withdraw', 
		component: WithdrawTips,  meta: { auth: true, permission:'report.tips.withdraw' }  
	},

	// Credit
	{ 
		path: '/credits', name: 'Report.Credit', 
		component: CreditReport,  meta: { auth: true, permission:'report.tips' } 
	},

	// Wallet Balance Report
	{ 
		path: '/customer/wallet', name: 'Report.WalletBalanceReport', 
		component: walletBalanceReport,  meta: { auth: true, permission:'report.tips' } 
	},
]

const router = createRouter({
	history: createWebHashHistory(),
	routes
})

router.beforeEach(function(to, _, next) {
	if (to.meta.auth && !store.getters.user) {
		next('/login');
	}
	else if(to.meta.unAuth && store.getters.user) {
		next('/dashboard');
	}
	else {
		next();
	}
});

export default router