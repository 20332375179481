<template>
    <nav class="main-header navbar navbar-expand navbar-white navbar-light">
        <ul class="navbar-nav">
            <li class="nav-item">
                <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
            </li>
            <li class="nav-item d-none d-sm-inline-block">
                <a class="nav-link text-dark" style="font-size: 25px; margin-top: -7px;">
                    {{$store?.getters?.user?.branch?.branch_name}}
                </a>
            </li>
        </ul>

        <ul class="navbar-nav ml-auto">
            <li class="nav-item">
                <a class="nav-link" data-widget="fullscreen" href="#" role="button">
                    <i class="fas fa-expand-arrows-alt"></i>
                </a>
            </li>
            <li class="nav-item dropdown">
                <a class="nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false" style="margin-top: -7px;">
                    <img :src="$store.getters.user.avatar" class="img-circle" alt="User Image" style="width: 40px !important; height: 40px !important;" />
                </a>
                <div class="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                    <!-- <router-link to="/transactions/create" class="dropdown-item"> <i class="fa fa-list"></i> Old Transactions </router-link> -->
                    <router-link to="/profile" class="dropdown-item"> <i class="fa fa-user"></i> Profile </router-link>
                    <router-link v-can="'auth.branch'" to="/change-branch" class="dropdown-item"> <i class="fas fa-home"></i> Change Branch </router-link>
                    <a href="#" class="dropdown-item" @click.prevent="logout()"> <i class="fas fa-sign-out-alt"></i> Logout </a>
                </div>
            </li>
        </ul>
    </nav>
</template>

<script>
    export default {
        name: "Header",
        data() {
            return {
                events:['click','mousemove','mousedown','scrool','keypress','load'],
                timer:null
            };
        },
        mounted() {
            // this.events.forEach(function(event) {
            //     window.addEventListener(event, this.resetTimer);
            // }, this);

            // this.setTimer();
        },
        methods: {
            // setTimer() {
            //     this.timer = setTimeout(this.logout, 5 * 60 * 1000);
            // },
            // resetTimer() {
            //     clearTimeout(this.timer);
            //     this.setTimer();
            // },
            logout() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store.dispatch("post", { uri: "logout", data: vm.$store.getters.user })
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("logout");
                    vm.$router.push("/login");
                    location.reload();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            }
        },
    }
</script>
