<template>
    <div class="content-wrapper">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="#">
                        <i class="fa fa-home"></i>
                        Dashboard
                    </a>
                </li>
                <li class="breadcrumb-item">
                    <a href="#"><i class="fa fa-cogs"></i>Configuration</a>
                </li>
                <li class="breadcrumb-item active">Permissions</li>
                <li class="breadcrumb-right">
                    <button type="button" @click="generateAbilities()" class="btn btn-sm btn-success">
                        Add Permission
                    </button>
                </li>
            </ol>
        </nav>
        <section class="content">
            <div class="container-fluid">
                <div class="row justify-content-md-center">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">Permissions</h3>
                                <div class="card-tools pb-2">
                                    <select class="form-control form-control-sm" :class="{'is-invalid': errors.role_id}" v-model="meta.role_id" @change="getAbilities">
                                        <option value="">Select Role</option>
                                        <option v-for="role,key in roles" :key="key" :value="role.role_id">{{ role.role }}</option>
                                    </select>
                                    <span v-if="errors.role_id" class="invalid-feedback">{{ errors.role_id[0] }}</span>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-3" v-for="(ability,key) in abilities" :key="key">
                                        <div class="form-check" @click="updateAbility(ability)">
                                            <input class="form-check-input" type="checkbox" :id="ability.ability_id" v-model="ability.status">
                                            <label class="form-check-label" :for="ability.ability_id">
                                                {{ ability.description }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        name: "Permissions.Index",
        data() {
            return {
                meta: {
                    role_id:'',
                },
                abilities: [],
                roles:[],
                errors: [],
            };
        },
        mounted() {
            let vm = this;
            vm.getRoles();
        },

        methods: {
            getRoles() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "getRoles" };
                vm.$store.dispatch("post", uri)
                .then(function (response) {
                    loader.hide();
                    vm.roles = response.data.data;
                    if(vm.roles.length!=0) {
                        vm.meta.role_id = vm.roles[0].role_id;
                        vm.getAbilities()
                    }
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getAbilities() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = {'uri':'getAbilities','data':vm.meta};
                vm.$store.dispatch("post", uri)
                .then(function (response) {
                    loader.hide();
                    vm.abilities = response.data.data;
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            updateAbility(ability) {
                let vm = this;
                vm.$store.dispatch("post", {'uri':'updateAbility','data':ability});
            },

            generateAbilities() {
                let vm = this;
                let loader = vm.$loading.show();
				let uri = {'uri':'generateAbilities','data':vm.meta};
				vm.$store.dispatch('post',uri)
				.then(function () {
                    loader.hide();
					vm.getAbilities()
				})
				.catch(function (error) {
                    loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
            }
        },
    };
</script>
