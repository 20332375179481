<template>
	<div class="wrapper" v-if="$store.getters.user">
		<Header></Header>
		<Menu></Menu>
		<router-view/>
		<Footer></Footer>
  </div>
	<div class="wrapper" v-else>
		<router-view/>
	</div>
</template>

<script>
	import Header from '@/components/Header.vue'
	import Menu from '@/components/Menu.vue'
	import Footer from '@/components/Footer.vue'
	export default {
		name: 'App',
		components: { Header, Menu, Footer },

		created() {
            //Read the status information in localStorage when the page is loaded
            if (localStorage.getItem("user") ) {
                this.$store.dispatch('setUser', JSON.parse(localStorage.getItem("user")))
                localStorage.removeItem('user')
            }
            if (localStorage.getItem("token") ) {
                this.$store.dispatch('setToken', localStorage.getItem("token"))
                localStorage.removeItem('token')
            }
            if (localStorage.getItem("permissions") ) {
                this.$store.dispatch('setPermissions', JSON.parse(localStorage.getItem("permissions")))
                localStorage.removeItem('permissions')
            }
            //Save the information in vuex to localStorage when the page is refreshed
            window.addEventListener("beforeunload",()=>{
                localStorage.setItem("user",  JSON.stringify(this.$store?.getters?.user))
                localStorage.setItem("token",  this.$store?.getters?.token)
                localStorage.setItem("permissions",  JSON.stringify(this.$store?.getters?.permissions))
            })
        }
	}
</script>
