<template>
    <div class="wrapper">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-12">
                    <div class="text-center mb-4 mt-2">
                        <img src="@/assets/logo2.png" alt="Logo" height="80" />

                        <h1 class="text-white" style="text-transform: uppercase;">{{ type }}</h1>
                    </div>
                    <div class="row">
                        <div v-for="waiting_stack,key in waitings" class="col-sm-3" :key="key">
                            <div class="card mb-4 stakes">
                                <div class="card-body">
                                    <div class="justify-content">
                                        <div class="justify-content-center">
                                            <h6 class="fw-800 pt-1">
                                                Stake : {{ waiting_stack?.stack?.stack }}
                                            </h6>
                                        </div>
                                        <div>
                                            <h6 class="fw-800 pt-1">
                                                Total Waiting : {{ waiting_stack?.waitings?.length }}
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" style="background-color: #034f3d; color: #fbcf35;">
                                    <h5 class="text-center my-2 mb-0">WAITING LIST</h5>
                                </div>
                                <div class="card-body p-0">
                                    <table class="table table-sm table-bordered">
                                        <thead>
                                            <tr>
                                                <th width="5%" class="text-center">No</th>
                                                <th width="95%">Name</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="waiting,j in waiting_stack.waitings" :key="j">
                                                <td class="text-center">{{ j+1 }}</td>
                                                <td>{{ waiting?.customer?.customer_name }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div v-for="call_time,key in call_times" class="col-sm-3" :key="key">
                            <div class="card stakes">
                                <div class="card-body">
                                    <div class="justify-content">
                                        <div class="justify-content-center">
                                            <h6 class="fw-800 pt-1">
                                                Table : {{ call_time?.table_log?.table?.table }}
                                            </h6>
                                        </div>
                                        <div>
                                            <h6 class="fw-800 pt-1">
                                                Total Players : {{ call_time?.customer_logs?.length }}
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" style="background-color: #034f3d; color: #fbcf35;">
                                    <h5 class="text-center my-2 mb-0">CALL TIME LIST</h5>
                                </div>
                                <div class="card-body p-0">
                                    <table class="table table-sm table-bordered">
                                        <thead>
                                            <tr>
                                                <th width="3%" class="text-center">No</th>
                                                <th width="77%">Name</th>
                                                <th width="20%" class="text-center">Call Time</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="customer_log,j in call_time.customer_logs" :key="j">
                                                <td class="text-center">{{ j+1 }}</td>
                                                <td v-if="changeColor(customer_log)" style="color: balck;">
                                                    {{ customer_log?.customer?.customer_name }}
                                                </td>
                                                <td v-else style="color: red;">
                                                    {{ customer_log?.customer?.customer_name }}
                                                </td>
                                                <td v-if="changeColor(customer_log)" class="text-center" style="font-weight: bolder; color: black;">
                                                    {{ $formatDate(calculateEndTime(customer_log), 'indian_time') }}
                                                </td>
                                                <td v-else class="text-center" style="font-weight: bolder; color: red;">
                                                    {{ $formatDate(calculateEndTime(customer_log), 'indian_time') }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    var moment = require('moment');
    export default {
        name: "Waiting",
        data() {
            return {
                meta:{
                    branch_id:1,
                },
                type: "Waiting List",
                waitings: [],
                call_times: [],
                interval_id: null,
                errors:[]
            };
        },
        mounted() {
            let vm = this;
            vm.getData();
            vm.interval_id = setInterval(vm.getData, 30000);
        },
        beforeUnmount() {
            clearInterval(this.interval_id);
        },
        methods: {
            getData() {
                let vm = this;
                if(vm.type=='Waiting List'){
                    vm.type = "Call Time";
                    vm.$store.dispatch("post", { uri: "getCallTimes", data: vm.meta })
                    .then(function (response) {
                        vm.waitings = [];
                        vm.call_times = response.data;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
                }
                else {
                    vm.type = "Waiting List";
                    vm.$store.dispatch("post", { uri: "getWaitings", data: vm.meta })
                    .then(function (response) {
                        vm.call_times = [];
                        vm.waitings = response.data;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
                }
            },

            calculateEndTime(customer_log) {
                let total_time = +customer_log.play_time + +customer_log.call_time + +customer_log.buffer_call_time + +customer_log.extend_time
                return moment(customer_log.start_date_time).add(total_time, 'minutes');
            },

            changeColor(customer_log) {
                let total_time = +customer_log.play_time + +customer_log.call_time + +customer_log.buffer_call_time + +customer_log.extend_time
                let end_time = moment(customer_log.start_date_time).add(total_time, 'minutes');
                let current_time = moment();
                if(end_time.diff(current_time, 'minutes')>=60) {
                    return true;
                }
                else {
                    return false;
                }
            }
        },
    };
</script>
<style scoped>
.justify-content {
    display: flex;
    justify-content: space-around;
}

.fw-800 {
    font-weight: 800;
}

.wrapper {
    background: linear-gradient(to top, #3a6073, #16222a) !important;
    min-height: 100vh;
}

.stakes {
    background: #ffe3b5;
}

th, td {
    font-size: 16px;
}

.card-body.p-0 .table tbody > tr > td:first-of-type, .card-body.p-0 .table tbody > tr > th:first-of-type, .card-body.p-0 .table tfoot > tr > td:first-of-type, .card-body.p-0 .table tfoot > tr > th:first-of-type, .card-body.p-0 .table thead > tr > td:first-of-type, .card-body.p-0 .table thead > tr > th:first-of-type {
    padding-left: 5px;
}

.card-body.p-0 .table tbody > tr > td:last-of-type, .card-body.p-0 .table tbody > tr > th:last-of-type, .card-body.p-0 .table tfoot > tr > td:last-of-type, .card-body.p-0 .table tfoot > tr > th:last-of-type, .card-body.p-0 .table thead > tr > td:last-of-type, .card-body.p-0 .table thead > tr > th:last-of-type {
    padding-right: 5px;
}
</style>
