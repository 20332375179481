<template>
    <div class="content-wrapper">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="#">
                        <i class="fa fa-home"></i>
                        Dashboard
                    </a>
                </li>
                <li class="breadcrumb-item active">Users</li>
                <li class="breadcrumb-right">
                    <router-link to="/users/create" class="btn btn-sm btn-success">
                        Add New
                    </router-link>
                </li>
            </ol>
        </nav>
        <section class="content">
            <div class="container-fluid">
                <div class="row justify-content-md-center">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">Users</h3>
                                <div class="card-tools">
                                    <div class="custom-control custom-switch custom-switch-off-success custom-switch-on-danger">
                                        <input type="checkbox" class="custom-control-input" id="trashed" v-model="meta.trashed" @click="isTrashed" />
                                        <label class="custom-control-label" for="trashed">Trashed</label>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="form-group">
                                    <input class="form-control form-control-sm" type="text" placeholder="Search" v-model="meta.search" @keyup="search" />
                                </div>
                                <table class="table table-responsive-sm table-bordered table-striped table-sm">
                                    <thead>
                                        <tr>
                                            <th class="text-center">#</th>
                                            <th @click="sort('name')">Name
                                                <span>
                                                    <i v-if="meta.keyword=='name' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.keyword=='name' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                                </span>
                                            </th>
                                            <th @click="sort('mobile_no')">Mobile Number
                                                <span>
                                                    <i v-if="meta.keyword=='mobile_no' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.keyword=='mobile_no' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                                </span>
                                            </th>
                                            <th @click="sort('role_id')">Role
                                                <span>
                                                    <i v-if="meta.keyword=='role_id' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.keyword=='role_id' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                                </span>
                                            </th>
                                            <th @click="sort('address')">Address
                                                <span>
                                                    <i v-if="meta.keyword=='address' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.keyword=='address' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                                </span>
                                            </th>
                                            <th @click="sort('kyc_status')" class="text-center">KYC
                                                <span>
                                                    <i v-if="meta.keyword=='kyc_status' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.keyword=='kyc_status' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                                </span>
                                            </th>
                                            <th class="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(user,key) in users" :key="key">
                                            <td class="text-center">{{meta.from+key}}</td>
                                            <td>{{user.name}}</td>
                                            <td>{{ $mask(user.mobile_no) }}</td>
                                            <td>{{user.role.role}}</td>
                                            <td>{{user.address}}</td>
                                            <td v-if="user.kyc_status=='0'"  class="text-center">
                                                <span class="badge badge-warning">Pending</span>
                                            </td>
                                            <td v-else class="text-center">
                                                <span class="badge badge-success">Approved</span>
                                            </td>
                                            <td class="text-center" v-if="!meta.trashed">
                                                <button class="btn btn-xs btn-outline-warning" data-toggle="tooltip" data-placement="bottom" title="Reset Password">
                                                    <i class="fas fa-lock" @click="resetUserPassword(user)"></i>
                                                </button>
                                                <button class="btn btn-xs btn-outline-success" data-toggle="tooltip" data-placement="bottom" title="Edit">
                                                    <i class="fas fa-pencil-alt" @click="edit(user)"></i>
                                                </button>
                                                <button class="btn btn-xs btn-outline-danger" data-toggle="tooltip" data-placement="bottom" title="Delete" @click="deleteUser(user)">
                                                    <i class="fas fa-trash-alt"></i>
                                                </button>
                                            </td>
                                            <td class="text-center" v-else>
                                                <button class="btn btn-xs btn-outline-success" @click="deleteUser(user)" data-toggle="tooltip" data-placement="bottom" title="Trash">
                                                    <i class="fas fa-trash-restore"></i>
                                                </button>
                                            </td>
                                        </tr>
                                        <tr v-if="users.length <= 0">
                                            <td colspan="20" style="text-align: center;">No Records Found</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Pagination from "@/components/Pagination.vue";
    export default {
        name: "User.Index",
        components: { Pagination },
        data() {
            return {
                status: true,
                meta: {
                    search: "",
                    order_by: "asc",
                    keyword: "name",
                    per_page: 10,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                    trashed: false,
                    branch_id:this.$store.getters.user.branch_id
                },
                user: {
                    name: "",
                    email_id: "",
                    mobile_no: "",
                    role: "",
                    password: "",
                    address: "",
                    avatar: "",
                },
                users: [],
                errors: [],
            };
        },
        mounted() {
            let vm = this;
            vm.index();
        },

        methods: {
            submitForm() {
                let vm = this;
                if (vm.status) {
                    vm.create();
                } else {
                    vm.update();
                }
            },

            edit(user) {
                this.$router.push("/users/" + user.user_id + "/edit");
            },

             search(){
               let vm = this;
               vm.meta.page = 1;
               vm.index();

            },

            index() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "paginateUsers", data: vm.meta })
                .then(function (response) {
                    vm.users = response.data.data;
                    vm.meta.totalRows = response.data.meta.total;
                    vm.meta.lastPage = response.data.meta.last_page;
                    vm.meta.from = response.data.meta.from;
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

           isTrashed() {
                let vm = this;
                vm.meta.trashed = !vm.meta.trashed;
                if(vm.meta.trashed)
                    vm.meta.page = 1
                vm.index();
            },

            create() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "addUser", data: vm.user };
                vm.$store
                    .dispatch("post", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "User is successfully created");
                        vm.currency = "";
                        vm.index();
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            discard() {
                let vm = this;
                vm.user = "";
                vm.status = true;
                vm.index();
            },

            update() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "updateUser", data: vm.user };
                vm.$store
                    .dispatch("post", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "User is successfully updated");
                        vm.user = "";
                        vm.status = true;
                        vm.index();
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            resetUserPassword(user) {
                if (confirm("Do you want to continue... ?")) {
                    let vm = this;
                    let loader = vm.$loading.show();
                    let uri = { uri: "resetUserPassword", data: user };
                    vm.$store
                    .dispatch("post", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Password is successfully updated to 1qaz2wsx");
                        vm.index();
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
                }
            },

            deleteUser(user) {
                if (confirm("Do you want to continue... ?")) {
                    let vm = this;
                    let loader = vm.$loading.show();
                    let uri = { uri: "deleteUser", data: user };
                    vm.$store
                        .dispatch("post", uri)
                        .then(function () {
                            loader.hide();
                            vm.$store.dispatch("success", "User is successfully deleted");
                            vm.index();
                            vm.trashed();
                        })
                        .catch(function (error) {
                            loader.hide();
                            vm.errors = error.response.data.errors;
                            vm.$store.dispatch("error", error.response.data.message);
                        });
                }
            },

            onPageChange(page) {
                this.meta.page = page;
                this.index();
            },
            sort(field) {
                this.meta.keyword = field;
                this.meta.order_by = this.meta.order_by=='asc' ? 'desc' : 'asc';
                this.index();
            },
        },
    };
</script>
