<template>
    <div class="content-wrapper">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="#">
                        <i class="fa fa-home"></i>
                        Dashboard
                    </a>
                </li>
                <li class="breadcrumb-item active">Messages</li>
                <li class="breadcrumb-right">
                    <button class="btn btn-success" data-toggle="modal" data-target="#message"><i class="fas fa-upload"></i> New Message</button>
                </li>
            </ol>
        </nav>
        <section class="content">
            <div class="container-fluid">
                <div class="row justify-content-md-center">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">Messages</h3>
                            </div>
                            <div class="card-body">
                                <div class="form-group">
                                    <input class="form-control form-control-sm" type="text" placeholder="Search" v-model="meta.search" @keyup="search" v-if="!meta.trashed" />
                                </div>
                                <table class="table table-responsive-sm table-bordered table-striped table-sm">
                                    <thead>
                                        <tr>
                                            <th class="text-center">#</th>
                                            <th @click="sort('sender_id')">Sender ID
                                                <span>
                                                    <i v-if="meta.keyword=='sender_id' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.keyword=='sender_id' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                                </span>
                                            </th>
                                            <th @click="sort('message_type')">Message Type
                                                <span>
                                                    <i v-if="meta.keyword=='message_type' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.keyword=='message_type' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                                </span>
                                            </th>
                                            <th @click="sort('template_id')">Template ID
                                                <span>
                                                    <i v-if="meta.keyword=='template_id' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.keyword=='template_id' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                                </span>
                                            </th>
                                            <th @click="sort('template_name')">Template Name
                                                <span>
                                                    <i v-if="meta.keyword=='template_name' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.keyword=='template_name' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                                </span>
                                            </th>
                                            <th class="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(message,key) in messages" :key="key">
                                            <td class="text-center">{{meta.from+key}}</td>
                                            <td>{{message.sender_id}}</td>
                                            <td>{{message.message_type}}</td>
                                            <td>{{message.template_id}}</td>
                                            <td>{{message.template_name}}</td>
                                            <td class="text-center">
                                                <button class="btn btn-xs btn-outline-primary" data-toggle="modal" data-target="#send" @click="Message(message)">
                                                    <i class="fas fa-upload"></i>
                                                </button>
                                                <button class="btn btn-xs btn-outline-danger" @click="deleteMessage(message)">
                                                    <i class="fas fa-trash-alt"></i>
                                                </button>
                                            </td>
                                        </tr>
                                        <tr v-if="messages.length <= 0">
                                            <td colspan="6" style="text-align: center;">No Records Found</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="modal fade" id="message">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">New Message</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <label for="sender_id">Sender ID *</label>
                                    <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.sender_id}" placeholder="Sender ID" v-model="message.sender_id" ref="sender_id" />
                                    <span v-if="errors.sender_id" class="invalid-feedback">{{ errors.sender_id[0] }}</span>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <label for="message_type">Message Type *</label>
                                    <select class="form-control form-control-sm" :class="{'is-invalid': errors.message_type}" v-model="message.message_type">
                                        <option value="">Select Message Type</option>
                                        <option value="Promotional">Promotional</option>
                                        <option value="Transactional">Transactional</option>
                                    </select>
                                    <span v-if="errors.message_type" class="invalid-feedback">{{ errors.message_type[0] }}</span>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <label for="template_id">Template ID *</label>
                                    <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.template_id}" placeholder="Template ID" v-model="message.template_id" ref="template_id" />
                                    <span v-if="errors.template_id" class="invalid-feedback">{{ errors.template_id[0] }}</span>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <label for="template_name">Template Name *</label>
                                    <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.template_name}" placeholder="Template Name" v-model="message.template_name" ref="template_name" />
                                    <span v-if="errors.template_name" class="invalid-feedback">{{ errors.template_name[0] }}</span>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <label for="message">Message *</label>
                                    <textarea rows="7" type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.message}" placeholder="Message" v-model="message.message"></textarea>
                                    <span v-if="errors.message" class="invalid-feedback">{{ errors.message[0] }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-success" @click="create()">
                            <i class="fa fa-upload"></i>
                            Submit
                        </button>
                        <button type="button" class="btn btn-danger" @click="discard" data-dismiss="modal" ref="closeMessage">
                            <i class="fa fa-times"></i>
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="send">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Groups</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Send To : </label>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="customer" value="customer" v-model="to">
                                    <label class="form-check-label" for="customer">
                                        Customer
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="database" value="database" v-model="to">
                                    <label class="form-check-label" for="database">
                                        Database
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-12" v-if="errors.to">
                                <span style="color:red">{{ errors.to[0] }}</span>
                            </div>
                            <div class="col-sm-12 mt-3">
                                <label>Groups : </label>
                            </div>
                            <div class="col-sm-12">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="all" @click="selectAll">
                                    <label class="form-check-label" for="all">
                                        Select All
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-4" v-for="(group,key) in groups" :key="key">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" :id="group.group_id" :value="group.group_id" v-model="grps">
                                    <label class="form-check-label" :for="group.group_id">
                                        {{ group.group }}
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-12" v-if="errors.groups">
                                <span style="color:red">{{ errors.groups[0] }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-success" @click="sendMessage()">
                            <i class="fa fa-upload"></i>
                            Submit
                        </button>
                        <button type="button" class="btn btn-danger" data-dismiss="modal" ref="Close">
                            <i class="fa fa-times"></i>
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Pagination from "@/components/Pagination.vue";
    export default {
        name: "message",
        components: { Pagination },
        data() {
            return {
                status: true,
                meta: {
                    search: "",
                    order_by: "desc",
                    keyword: "message_id",
                    per_page: 10,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                    trashed: false,
                },
                message: {
                    message_id: "",
                    sender_id: "",
                    message_type: "Promotional",
                    template_id: "",
                    template_name: "",
                    message: "",
                    to:[],
                    groups: [],
                },
                grps: [],
                to:[],
                groups: [],
                messages: [],
                errors: [],
            };
        },

        mounted() {
            let vm = this;
            vm.index();
        },

        methods: {
            submitForm() {
                let vm = this;
                if (vm.status) {
                    vm.create();
                } else {
                    vm.update();
                }
            },

            index() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "paginateMessages", data: vm.meta })
                .then(function (response) {
                    vm.messages = response.data.data;
                    vm.meta.totalRows = response.data.meta.total;
                    vm.meta.lastPage = response.data.meta.last_page;
                    vm.meta.from = response.data.meta.from;
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                    vm.getGroups();
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getGroups() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getGroups" })
                .then(function (response) {
                    vm.groups = response.data.data;
                    console.log(vm.groups);
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            create() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.message.user_id = vm.$store.getters.user.user_id;
                let uri = { uri: "addMessage", data: vm.message };
                vm.$store.dispatch("post", uri)
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "Message is successfully created");
                    vm.$refs.closeMessage.click();
                    vm.discard();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            Message(message) {
                let vm = this;
                vm.message = message;
            },

            sendMessage() {
                if (confirm("Do you want to continue... ?")) {
                    let vm = this;
                    let loader = vm.$loading.show();
                    vm.message.groups = vm.grps;
                    vm.message.to = vm.to;
                    let uri = { uri: "sendMessage", data: vm.message };
                    vm.$store.dispatch("post", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Message is successfully sent");
                        vm.$refs.Close.click();
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
                }
            },

            deleteMessage(message) {
                if (confirm("Do you want to continue... ?")) {
                    let vm = this;
                    let loader = vm.$loading.show();
                    let uri = { uri: "deleteMessage", data: message };
                    vm.$store.dispatch("post", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Message is successfully deleted");
                        vm.discard();
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
                }
            },

            discard() {
                let vm = this;
                vm.message.template_id = "";
                vm.message.sender_id = "";
                vm.message.message_type = "";
                vm.message.template_name = "";
                vm.message.message = "";
                vm.errors = [];
                vm.grps = [];
                vm.status = true;
                vm.index();
            },

            onPageChange(page) {
                this.meta.page = page;
                this.index();
            },

            search() {
                let vm = this;
                vm.meta.page = 1;
                vm.index();
            },

            selectAll() {
                let vm = this;
                if(vm.grps.length==0) {
                    vm.groups.map(function(element){           
                        vm.grps.push(element.group_id)
                    });
                }
                else {
                    vm.grps = [];
                }
            },
            sort(field) {
                this.meta.keyword = field;
                this.meta.order_by = this.meta.order_by=='asc' ? 'desc' : 'asc';
                this.index();
            },
        },
    };
</script>
