<template>
    <div class="content-wrapper">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="#">
                        <i class="fa fa-home"></i>
                        Dashboard
                    </a>
                </li>
                <li class="breadcrumb-item">Expense</li>
                <li class="breadcrumb-item active">Voucher No : {{ expense?.voucher_no }}</li>
                <li class="breadcrumb-right" v-if = "expense.payment_status == 'Pending' && expense.type == 'Expense'" >
                    <button class="btn btn-xs btn-outline-success" title="Edit" @click="editExpense(expense)">
                        <i class="fas fa-pencil-alt" ></i>
                    </button>
                    <button class="btn btn-xs btn-outline-danger" title="Delete" @click="deleteExpense(expense)">
                        <i class="fas fa-trash-alt"></i>
                    </button>
                </li>
               
            </ol>
        </nav>
        <section class="content">
            <div class="container-fluid">
                <div class="row justify-content-md-center">
                    <div class="col-sm-4">
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">Voucher : {{ expense?.voucher_no }}</h3>
                            </div>
                            <div class="card-body">
                                <table class="table table-striped table-bordered table-sm">
                                    <tbody>
                                        <tr>
                                            <th>Voucher No</th>
                                            <td>{{ expense?.voucher_no }}</td>
                                        </tr>
                                        <tr>
                                            <th>Voucher Date</th>
                                            <td>{{ expense?.voucher_date }}</td>
                                        </tr>
                                        <tr>
                                            <th>Vendor</th>
                                            <td>{{ expense?.vendor?.vendor_name }}</td>
                                        </tr>
                                        <tr>
                                            <th>Payment Term</th>
                                            <td>{{ expense?.payment_term?.payment_term}}</td>
                                        </tr>
                                        <tr>
                                            <th>Account</th>
                                            <td>{{ expense?.account?.account }}</td>
                                        </tr>
                                        <tr>
                                            <th>Category</th>
                                            <td>{{ expense?.category?.category }}</td>
                                        </tr>
                                        <tr>
                                            <th>Payment Mode</th>
                                            <td>{{ expense?.payment_mode?.payment_mode }}</td>
                                        </tr>
                                        <tr>
                                            <th>Amount</th>
                                            <td>{{ expense?.amount }}</td>
                                        </tr>
                                        <tr>
                                            <th>TDS</th>
                                            <td>{{ expense?.tds }}</td>
                                        </tr>
                                        <tr>
                                            <th>TDS Amount</th>
                                            <td>{{ expense?.tds_amount }}</td>
                                        </tr>
                                        <tr>
                                            <th>Payable Amount</th>
                                            <td>{{ expense?.payable_amount }}</td>
                                        </tr>
                                        <tr v-if="expense?.reference_no">
                                            <th>Reference No</th>
                                            <td>{{ expense?.reference_no }}</td>
                                        </tr>
                                        <tr v-if="expense?.reference_date">
                                            <th>Reference Date</th>
                                            <td>{{ expense?.reference_date }}</td>
                                        </tr>
                                        <tr v-if="expense?.approved_by">
                                            <th>Approved By</th>
                                            <td>{{ expense?.approved_by }}</td>
                                        </tr>
                                        <tr v-if="expense?.note">
                                            <th>Note</th>
                                            <td>{{ expense?.note }}</td>
                                        </tr>
                                        <tr v-if="expense?.attachment_name">
                                            <th>Attachment</th>
                                            <td><a target="_blank" :href=expense?.attachment><i class="fa fa-download"></i></a></td>
                                        </tr>
                                        <tr v-if="expense?.paid_by_name?.name">
                                            <th>Paid By</th>
                                            <td>{{ expense?.paid_by_name?.name }}</td>
                                        </tr>
                                        <tr v-if="expense?.payment_date ">
                                            <th>Payment Date</th>
                                            <td>{{ expense?.payment_date }}</td>
                                        </tr>
                                        <tr v-if="expense?.payment_status">
                                            <th>Payment Status</th>
                                            <td>{{ expense?.payment_status }}</td>
                                        </tr>
                                        <tr v-if="expense?.payment_attachment_name">
                                            <th>Payment Attachment</th>
                                            <td><a target="_blank" :href=expense?.payment_attachment><i class="fa fa-download"></i></a></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-8">
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">Print Voucher</h3>
                            </div>
                            <div class="card-body">
                                <iframe :src="pdf" width="100%" height="500" frameborder="0"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        name: "Expense.View",
        data() {
            return {
                expense: {},
                pdf:'',
                errors: [],
            };
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                let uri = { uri: "showExpense", data: { expense_id: to.params.expense_id } };
                vm.$store.dispatch("post", uri)
                .then(function (response) {
                    vm.expense = response.data.data;
                    vm.pdf = vm.$store.getters.pdfUrl+'ViewExpense?expense_id='+vm.expense.expense_id;
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            });
        },

        methods: {
            editExpense(expense) {
                this.$router.push("/expenses/" + expense.expense_id + "/edit");
            },
            deleteExpense(expense) {
                if(confirm("Do you want to continue... ?")) {
                    let vm = this;
                    let loader = vm.$loading.show();
                    let uri = { uri: "deleteExpense", data: expense };
                    vm.$store.dispatch("post", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Expense is successfully deleted");
                        vm.$router.push("/expenses");
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
                }
            },
            
        },
    };
</script>
