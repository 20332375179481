<template>
    <div class="content-wrapper">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="#">
                        <i class="fa fa-home"></i>
                        Dashboard
                    </a>
                </li>
                <li class="breadcrumb-item">Customer</li>
                <li class="breadcrumb-item active">{{ customer.customer_name }}</li>
                <li class="breadcrumb-right">
                    <button class="btn btn-sm btn-primary" data-toggle="modal" data-target="#add_groups">
                        <i class="fa fa-plus"></i>
                        Assign Groups
                    </button>
                    <button v-can="'customer.credit_limit'" class="btn btn-sm btn-secondary" data-toggle="modal" data-target="#credit_limit">
                        <i class="fa fa-credit-card"></i>
                        Credit Limit
                    </button>
                </li>
            </ol>
        </nav>

        <section class="content">
            <div class="container-fluid">
                <div class="row justify-content-md-center">
                    <div class="col-sm-4">
                        <div class="card card-widget widget-user">
                            <div class="widget-user-header bg-primary">
                                <h3 class="widget-user-username">{{ customer.customer_name }}</h3>
                            </div>
                            <div class="widget-user-image">
                                <img class="elevation-2" :src="customer.avatar" alt="Photo" />
                            </div>
                            <div class="card-body p-0" style="margin-top: 70px;">
                                <table class="table table-striped table-bordered table-sm">
                                    <tbody>
                                        <tr>
                                            <td>Customer No</td>
                                            <td>{{ customer.customer_no }}</td>
                                        </tr>
                                        <tr>
                                            <td>Mobile No</td>
                                            <td v-if="edit==false">
                                                {{ customer.mobile_no }}
                                                <a class="float-right" href="#" @click.prevent="editCustomer">Edit</a>
                                            </td>
                                            <td v-else>
                                                <input type="number" v-model="customer.mobile_no" :class="{'is-invalid': errors.mobile_no}" @keyup.enter="updateCustomer">
                                                <span v-if="errors.mobile_no" class="invalid-feedback">{{ errors.mobile_no[0] }}</span>
                                                <a class="float-right" href="#" @click.prevent="updateCustomer">Update</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>E-mail</td>
                                            <td>{{ customer.email }}</td>
                                        </tr>
                                        <tr v-if="customer.date_of_birth!=''">
                                            <td>Date of Birth</td>
                                            <td>{{ customer.date_of_birth }}</td>
                                        </tr>
                                        <tr v-if="customer.city!=''">
                                            <td>City</td>
                                            <td>{{ customer.city }}</td>
                                        </tr>
                                        <tr v-if="customer.pincode!=''">
                                            <td>Pincode</td>
                                            <td>{{ customer.pincode }}</td>
                                        </tr>
                                        <tr v-for="document,key in customer?.documents" :key="key">
                                            <td v-if="document.document_type=='pan'">PAN No</td>
                                            <td v-if="document.document_type=='aadhaar'">Aadhaar No</td>
                                            <td v-if="document.document_type=='bank'">Account No</td>
                                            <td>{{ document.document_no_1 }}</td>
                                        </tr>
                                        <tr v-for="document,key in customer?.documents" :key="key">
                                            <td v-if="document.document_type=='bank'">IFSC Code</td>
                                            <td v-if="document.document_type=='bank'">{{ document.document_no_2 }}</td>
                                        </tr>
                                        <tr v-for="document,key in customer?.documents" :key="key">
                                            <td v-if="document.document_type=='pan' && document.document_image">Download PAN</td>
                                            <td v-if="document.document_image"><a :href="document.document_image" target="_blank">Download</a></td>
                                        </tr>
                                        <!-- <tr v-if="customer?.reference_type">
                                            <td>Reference Type</td>
                                            <td>{{ customer.reference_type?.reference_type }}</td>
                                        </tr> -->
                                        <tr v-if="customer?.referral">
                                            <td>Referred By</td>
                                            <td>{{ customer.referral?.customer?.customer_name }}</td>
                                        </tr>
                                        <tr v-if="customer?.referral">
                                            <td>Referral Code</td>
                                            <td>{{ customer.referral?.referral_code }}</td>
                                        </tr>
                                        <tr v-if="customer.credit_limit!=0">
                                            <td>Credit Limit</td>
                                            <td>{{ customer.credit_limit }}</td>
                                        </tr>
                                        <tr>
                                            <td>KYC Status</td>
                                            <td v-if="customer.kyc_status =='0'">Pending</td>
                                            <td v-if="customer.kyc_status =='1'">Approved</td>
                                        </tr>
                                        <!-- <tr>
                                            <td>Admin Status</td>
                                            <td v-if="customer.admin_status =='0'">Pending</td>
                                            <td v-if="customer.admin_status =='1'">Approved</td>
                                        </tr> -->
                                        <!-- <tr v-if="customer?.customer_groups?.length!=0">
                                            <td>Groups</td>
                                            <td>
                                                <span class="badge badge-success mr-1" v-for="(group,key) in customer.customer_groups" :key="key">{{group?.group?.group}} &nbsp; </span>
                                            </td>
                                        </tr> -->
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-8">
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">Transactions</h3>
                                <div class="text-right">
                                    Wallet Balance : {{ balance }}
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label for="from_date">From Date</label>
                                            <input class="form-control form-control-sm" type="date" placeholder="Search" v-model="meta.from_date" @change="getTransactions" />
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label for="from_date">To Date</label>
                                            <input class="form-control form-control-sm" type="date" placeholder="Search" v-model="meta.to_date" @change="getTransactions" />
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label for="from_date">Transaction Type</label>
                                            <select class="form-control form-control-sm" v-model="meta.transaction_type" @change="getTransactions">
                                                <option value="">Select Transaction Type</option>
                                                <option value="Deposit">Deposit</option>
                                                <option value="Withdraw">Withdraw</option>
                                                <option value="TDS">TDS</option>
                                                <option value="BuyIn">BuyIn</option>
                                                <option value="CashOut">CashOut</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group">
                                            <label for="from_date">Status</label>
                                            <select class="form-control form-control-sm" v-model="meta.status" @change="getTransactions">
                                                <option value="">Select Status</option>
                                                <option value="Approved">Approved</option>
                                                <option value="Pending">Pending</option>
                                                <option value="Rejected">Rejected</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <table class="table table-responsive-sm table-bordered table-striped table-sm">
                                    <thead>
                                        <tr>
                                            <th class="text-center">#</th>
                                            <th>T. No</th>
                                            <th>T. Date-Time</th>
                                            <th>T. Type</th>
                                            <th class="text-right">Amount</th>
                                            <th class="text-right">Fee</th>
                                            <th class="text-center">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(transaction,key) in transactions" :key="key" @click="view_transaction(transaction)">
                                            <td class="text-center">{{meta.from+key}}</td>
                                            <td>{{transaction.transaction_no}}</td>
                                            <td>{{transaction.transaction_date_time}}</td>
                                            <td>{{transaction.transaction_type}}</td>
                                            <td class="text-right">{{transaction.amount}}</td>
                                            <td class="text-right">{{transaction.fee}}</td>
                                            <td class="text-center">
                                                <span v-if="transaction.status=='Pending'" class="badge badge-warning">
                                                    Pending
                                                </span>
                                                <span v-if="transaction.status=='Approved'" class="badge badge-success">
                                                    Approved
                                                </span>
                                                <span v-if="transaction.status=='Rejected'" class="badge badge-danger">
                                                    Rejected
                                                </span>
                                            </td>
                                        </tr>
                                        <tr v-if="transactions.length <= 0">
                                            <td colspan="7" style="text-align: center;">No Records Found</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div class="modal fade" id="add_groups">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Assign Groups</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-sm-4" v-for="(group,key) in groups" :key="key">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" :id="group.group_id" :value="group.group_id" v-model="customer_groups">
                                    <label class="form-check-label" :for="group.group_id">
                                        {{ group.group }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-dismiss="modal" ref="CloseGroup">
                            <i class="fa fa-times"></i> Close
                        </button>
                        <button type="button" class="btn btn-success" @click="addGroup()">
                            <i class="fas fa-save"></i> Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="credit_limit">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Credit Limit</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <label for="credit_limit">Credit Limit *</label>
                                    <input type="number" class="form-control form-control-sm" :class="{'is-invalid': errors.credit_limit}" placeholder="Credit Limit" v-model="customer.credit_limit" ref="credit_limit" />
                                    <span v-if="errors.credit_limit" class="invalid-feedback">{{ errors.credit_limit[0] }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-dismiss="modal" ref="Close">
                            <i class="fa fa-times"></i> Close
                        </button>
                        <button type="button" class="btn btn-success" @click="creditLimit()">
                            <i class="fa fa-save"></i> Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Pagination from "@/components/Pagination.vue";
    export default {
        name: "Proj-Customer.View",
        components: { Pagination },
        data() {
            return {
                edit:false,
                meta: {
                    from_date:"",
                    to_date:"",
                    transaction_type: "",
                    status:"",
                    customer_id: "",
                    order_by: "desc",
                    keyword: "transaction_no",
                    per_page: 10,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                    branch_id:this.$store.getters.user.branch_id
                },
                customer: {},
                balance:0,
                transactions:[],
                customer_groups: [],
                groups: [],
                errors: [],
            };
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.getGroups();
                let uri = { uri: "showCustomer", data: { customer_id: to.params.customer_id } };
                vm.$store.dispatch("post", uri)
                .then(function (response) {
                    vm.customer = response.data.data;
                    vm.meta.customer_id = vm.customer.customer_id;
                    let selected_groups = response.data.data.customer_groups;
                    vm.customer_groups = [];
                    selected_groups.forEach(selected_group => {
                        vm.customer_groups.push(selected_group.group_id)
                    });
                    vm.getTransactions()
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            });
        },

        methods: {

            getGroups() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getGroups" })
                .then(function (response) {
                    vm.groups = response.data.data;
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getTransactions() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "paginateTransactions", data: vm.meta })
                .then(function (response) {
                    vm.transactions = response.data.data;
                    vm.meta.totalRows = response.data.meta.total;
                    vm.meta.lastPage = response.data.meta.last_page;
                    vm.meta.from = response.data.meta.from;
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                    vm.getCustomerWallet()
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getCustomerWallet() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "customer/transactions/getCustomerWallet",data:this.customer })
                .then(function (response) {
                    vm.balance = response.data['opening_balance'];
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            creditLimit() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "updateCreditLimit", data: vm.customer };
                vm.$store.dispatch("post", uri)
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "Credit Limit is successfully changed");
                    vm.$refs.Close.click();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            addGroup() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.customer.groups = vm.customer_groups;
                let uri = { uri: "addCustomerGroup", data: vm.customer };
                vm.$store.dispatch("post", uri)
                .then(function (response) {
                    loader.hide();
                    vm.$store.dispatch("success", "Customer Group is successfully updated");
                    vm.customer.customer_groups = response.data.data
                    vm.$refs.CloseGroup.click();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },
            
            onPageChange(page) {
                this.meta.page = page;
                this.getTransactions();
            },

            discard() {
                let vm = this;
                vm.customer_groups = [];
            },

            editCustomer() {
                let vm = this;
                vm.edit = true;
            },
            
            updateCustomer() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "updateCustomerMobile", data: vm.customer };
                vm.$store.dispatch("post", uri)
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "Mobile number is successfully updated");
                    vm.edit = false;
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            }
        },
    };
</script>
